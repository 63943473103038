import { providerFor } from "../provider";

class LocalStorageUserSession {
  constructor({ localStorageImplementation } = {}) {
    this.localStorage = localStorageImplementation || localStorage;
  }

  isUserLoggedIn() {
    return this.localStorage.getItem(this.CSRF_TOKEN_KEY) !== null;
  }

  getUserId() {
    return this.localStorage.getItem(this.USER_ID_KEY);
  }

  getUserName() {
    return this.localStorage.getItem(this.USER_NAME_KEY);
  }

  getUserUsername() {
    return this.localStorage.getItem(this.USER_USERNAME_KEY);
  }

  clearUserUsername() {
    __filename;
    return this.localStorage.removeItem(this.USER_USERNAME_KEY);
  }

  getLang() {
    return this.localStorage.getItem(this.USER_LANGUAGE);
  }

  getUserPassword() {
    const password = this.localStorage.getItem(this.USER_PASSWORD_KEY);
    this.localStorage.removeItem(this.USER_PASSWORD_KEY);
    return password;
  }

  clearUserPassword() {
    return this.localStorage.removeItem(this.USER_PASSWORD_KEY);
  }

  getCsrfToken() {
    return this.localStorage.getItem(this.CSRF_TOKEN_KEY);
  }

  getEntityId() {
    return this.localStorage.getItem(this.ENTITY_ID_KEY);
  }

  getEntityName() {
    return this.localStorage.getItem(this.ENTITY_NAME_KEY);
  }

  getConnectProfile() {
    return this.localStorage.getItem(this.CONNECT_PROFILE_KEY);
  }

  getRedirect() {
    return this.localStorage.getItem(this.REDIRECT_KEY) || "/";
  }

  clearRedirect() {
    this.localStorage.removeItem(this.REDIRECT_KEY);
  }

  setUserUsername(username) {
    this.localStorage.setItem(this.USER_USERNAME_KEY, username);
  }

  setUserPassword(password) {
    this.localStorage.setItem(this.USER_PASSWORD_KEY, password);
  }

  setLang(lang) {
    if (lang) this.localStorage.setItem(this.USER_LANGUAGE, lang);
  }

  setNavigationUrl(appKey, url) {
    return this.localStorage.setItem(`${this.NAVIGATE_KEY}/${appKey}`, url);
  }

  getNavigationUr(appKey) {
    return this.localStorage.getItem(`${this.NAVIGATE_KEY}/${appKey}`);
  }

  clearNavigationUr(appKey) {
    return this.localStorage.removeItem(`${this.NAVIGATE_KEY}/${appKey}`);
  }

  save({ id, name, language }, csrfToken) {
    this.localStorage.setItem(this.USER_ID_KEY, id);
    this.localStorage.setItem(this.USER_NAME_KEY, name);
    this.localStorage.setItem(this.CSRF_TOKEN_KEY, csrfToken);
    this.setLang(language);
  }

  update({ id, name, email, username, updated_at, intercom_hash, language, settings, entity }) {
    this.localStorage.setItem(this.USER_ID_KEY, id);
    this.localStorage.setItem(this.USER_NAME_KEY, name || "");
    this.localStorage.setItem(this.USER_USERNAME_KEY, username || email || "");
    this.localStorage.setItem(this.USER_EMAIL_KEY, email || "");
    this.localStorage.setItem(this.USER_SIGN_UP_TIMESTAMP_KEY, new Date(updated_at).getTime() / 1000);
    this.localStorage.setItem(this.USER_INTERCOM_HASH_KEY, intercom_hash);
    this.localStorage.setItem(this.CONNECT_PROFILE_KEY, settings.other_info?.connect_profile || "");
    this.localStorage.setItem(this.ENTITY_ID_KEY, entity?.id || "");
    this.localStorage.setItem(this.ENTITY_NAME_KEY, entity?.name || "");
    this.localStorage.setItem(this.ENTITY_KIND_KEY, entity?.kind || "");
    this.setLang(language);
  }

  reset(location = null) {
    const language = this.getLang();
    this.localStorage.clear();
    this.setLang(language);

    const page_size = this.localStorage.getItem("page_size") || 8;
    this.localStorage.clear();
    this.localStorage.setItem("page_size", page_size);

    if (location) this.localStorage.setItem(this.REDIRECT_KEY, location);
  }

  USER_ID_KEY = "user_id";
  USER_NAME_KEY = "user_name";
  USER_USERNAME_KEY = "user_username";
  USER_EMAIL_KEY = "user_email";
  USER_PASSWORD_KEY = "user_password";
  USER_LANGUAGE = "user_language";
  USER_SIGN_UP_TIMESTAMP_KEY = "user_sign_up_timestamp";
  USER_INTERCOM_HASH_KEY = "user_hash";
  CSRF_TOKEN_KEY = "csrf_token";
  REDIRECT_KEY = "redirect_url";
  ENTITY_ID_KEY = "entity_id";
  ENTITY_NAME_KEY = "entity_name";
  ENTITY_KIND_KEY = "entity_type";
  CONNECT_PROFILE_KEY = "connect_profile";
  NAVIGATE_KEY = "navigate_url";
}

export { LocalStorageUserSession };
export default providerFor(() => new LocalStorageUserSession());
