import provideUserSession from "./UserSession";
import { providerFor } from "../provider";

class TrackEvent {
  constructor(userSession) {
    this.userSession = userSession || provideUserSession();
  }

  getEnvironment = (host) => {
    if (/localhost/.test(host)) return "local";
    if (/allfunds.dev/.test(host)) return "staging";
    if (/allfunds.com/.test(host)) return "production";
    return "unknown";
  };

  track(trakingOptions) {
    this.run({ eventType: "track", ...trakingOptions });
  }

  identify() {
    this.run({ eventType: "identify" });
  }

  async run({
    eventType,
    eventName,
    eventCategory,
    eventSection,
    eventProperties = {},
  }) {
    const enviroment = this.getEnvironment(window.location.host);
    const url = "https://app.allfunds.com/api/internal/tracking_events";

    window?.dataLayer?.push({
      event: eventName === "Load Page" ? "pageview" : eventType,
      eventName,
      eventCategory,
      eventSection,
    });

    return new Promise((resolve, reject) => {
      const properties = {
        product: "connect-next",
        source: "webappJS",
        type: "ui-interaction",
        eventCategory,
        eventSection,
        ...eventProperties,
      };

      const context = {
        environment: enviroment,
        userAgent: navigator.userAgent,
        page: {
          path: window.location.pathname,
          search: window.location.search,
          url: window.location.href,
          title: document.title,
        },
      };
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": this.userSession.getCsrfToken(),
        },
        body: JSON.stringify({
          type: eventType,
          name: eventName || "unknown",
          category: eventCategory || "unknown",
          section: eventSection,
          properties,
          context,
        }),
      }).then((response) => {
        if (!response.ok) reject(new Error("Track Event Error"));
        resolve(true);
      });
    }).catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e, { eventType, eventName });
    });
  }
}
export default providerFor(() => new TrackEvent());
