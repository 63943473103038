export const providerFor = (factory) => {
  let instance = null;
  return (dependencies) => {
    if (instance !== null) return instance;
    instance = factory(dependencies);
    return instance;
  };
};

export const actionFactory = (factory) => (dependencies) => {
  const action = factory(dependencies);
  return action.run.bind(action);
};

export const actionProviderFor = (factory) => providerFor(actionFactory(factory));
